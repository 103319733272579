import React, { memo, useEffect, useState, useRef } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Keycloak from 'keycloak-js'
import isEmpty from 'lodash/isEmpty'
import { getFirestore, collection, query, onSnapshot } from 'firebase/firestore'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { isMobile, isIOS } from 'react-device-detect'
import axios from 'axios'

import {
  AUTH,
  GET_FIREBASE_TOKEN,
  GET_FIREBASE_CONFIGS,
  GET_PROFILES,
  GET_PROFILE_PREFERENCES_SUCCESS,
  GET_SUBSCRIPTION,
  SET_NOTIFICATION_RECEIVED,
  SUBSCRIBE_NOTIFICATIONS,
} from '../../redux/actions'

import '../../App.scss'
import NavMenu from '../NavMenu'
import { keycloakConfig } from '../../utils/keycloak'
import Loading from '../Loading'
import ScrollToTop from '../ScrollTop'
import DirectingComponent from '../home/DirectingComponent'
import HomeComponent from '../home/HomeComponent'
import PreHomeComponent from '../home/PreHomeComponent'
import SerieComponent from '../serie/SerieComponent'
import EventComponent from '../event/EventComponent'
import CategoriesComponent from '../categories/CategoriesComponent'
import FavoritesComponent from '../favorites/FavoritesComponent'
import PlayerComponent from '../player/PlayerComponent'
import ProfileComponent from '../profiles/ProfileComponent'
import ProfileSelectionComponent from '../profiles/ProfileSelectionComponent'
import ProfileEditComponent from '../profiles/ProfileEditComponent'
import ProfilePlanComponent from '../profiles/ProfilePlanComponent'
import CompensationModalComponent from '../compensation/CompensationModalComponent'
import PlansComponent from '../plans/PlansComponent'
import PaymentValidationComponent from '../payment/PaymentValidationComponent'
import ContactComponent from '../profiles/ContactComponent'
import OnboardingComponent from '../login/OnboardingComponent'
import DownloadAppContainer from '../../containers/login/DownloadAppContainer'
import RegisterComponent from '../login/RegisterComponent'
import Error429 from '../Error429'
import axiosInterceptor, { setAxiosErrorHandler } from '../../utils/axiosConfig' // Importa la función

export default memo(function Auth({ remoteConfig }) {
  const { isLoading, keycloak, isAuthenticated, firebase, firebaseConfigs } = useSelector((state) => state.auth)
  const { data: subscription, isFreePlan, isLoading: isLoadingSubscriptions } = useSelector((state) => state.subscription)
  const { data: profiles, isLoading: isLoadingProfiles, currentProfile } = useSelector((state) => state.profiles)
  const [currentLocation, setCurrentLocation] = useState('/')
  const [showError429, setShowError429] = useState(false)
  const [isGptLoaded, setIsGptLoaded] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [preHomeData, setPreHomeData] = useState({})
  const keycloakInstance = useRef(new Keycloak(keycloakConfig)).current
  const didInit = useRef(false)
  const dispatch = useDispatch()
  const auth = getAuth()

  setAxiosErrorHandler(setShowError429)
  axiosInterceptor(axios)

  useEffect(() => {
    if (!isEmpty(firebase) && isAuthenticated) {
      const q = query(
        collection(
          getFirestore(),
          'settings'
        )
      )
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data()
            setPreHomeData({ active: data.active ? data.active : false, title: data.title, url: data.url, textButton: data.textButton, timeout: data.timeout })
          } else {
            setPreHomeData({ active: false })
          }
        })
      })
      return () => unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase, isAuthenticated])

  useEffect(() => {
    if (Object.keys(firebaseConfigs).length === 0) {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const plansShowFirstVisit = getValue(remoteConfig, 'PLANS_SHOW_FIRST_VISIT_ON_WEB').asBoolean()
          const useImageCDN = getValue(remoteConfig, 'USE_IMAGE_CDN_ON_WEB').asBoolean()
          dispatch(GET_FIREBASE_CONFIGS({ plansShowFirstVisit, useImageCDN: false }))
        })
    }
    if (didInit.current) {
      return
    }
    didInit.current = true
    keycloakInstance
      .init({
        onLoad: 'check-sso',
        promiseType: 'native',
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        dispatch(
          AUTH({
            keycloak: keycloakInstance,
            isAuthenticated: authenticated,
          })
        )
        dispatch(GET_FIREBASE_TOKEN({ keycloak: keycloakInstance }))
        const messaging = getMessaging()
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_NOTIFY_VAPIDKEY,
        }).then((currentNotifyToken) => {
          if (currentNotifyToken) {
            dispatch(SUBSCRIBE_NOTIFICATIONS({ keycloak: keycloakInstance, currentNotifyToken }))
          }
        }).catch(e => {
        })
        onMessage(messaging, (payload) => {
          dispatch(SET_NOTIFICATION_RECEIVED(payload.notification))
        })
      })
      .catch((err) =>
        console.error("Instance.init catch", err)
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(firebase) && isEmpty(auth.currentUser)) {
      signInWithCustomToken(auth, firebase.token)
      // .then((userCredential) => { })
      // TODO: Mostrar error en una ventana o modal
      // .catch((error) => {
      //   var errorCode = error.code
      //   var errorMessage = error.message
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase])

  useEffect(() => {
    if (isAuthenticated && isEmpty(subscription) && isLoadingSubscriptions && keycloak) {
      dispatch(GET_SUBSCRIPTION({ keycloak }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, subscription, keycloak])

  useEffect(() => {
    if (currentLocation === '/cuenta') {
      dispatch(GET_SUBSCRIPTION({ keycloak, appendRefound: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation])

  useEffect(() => {
    if (isAuthenticated && isEmpty(profiles) && !isEmpty(firebase) && keycloak) {
      dispatch(GET_PROFILES({ keycloak }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, profiles, firebase, keycloak])

  useEffect(() => {
    if (!isEmpty(profiles) && keycloak) {
      const q = query(
        collection(
          getFirestore(),
          'users',
          keycloak.subject,
          'profiles',
          currentProfile.id,
          'series'
        )
      )
      var unsubscribe = onSnapshot(q, (querySnapshot) => {
        var preferences = []
        querySnapshot.forEach((doc) => preferences.push(doc.data()))
        dispatch(GET_PROFILE_PREFERENCES_SUCCESS(preferences))
      })
    }

    return () => {
      !isEmpty(profiles) && unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles, currentProfile, keycloak])

  // Script adds GAM
  useEffect(() => {
    let scriptGpt = document.getElementById("gpt-script")
    if (!isLoadingSubscriptions && isFreePlan && scriptGpt === null) {
      const tagScript = document.createElement('script')
      tagScript.setAttribute('id', 'gpt-script')
      tagScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
      document.body.appendChild(tagScript)
      tagScript.onload = () => {
        setIsGptLoaded(true)
      }
      tagScript.onerror = () => {
        setIsGptLoaded(true)
      }
    } else {
      setIsGptLoaded(true)
    }
  }, [isFreePlan, isLoadingSubscriptions])

  return isLoading || (isLoadingProfiles && isAuthenticated) || !isGptLoaded ? (
    <Loading />
  ) : (
    <div className='app'>
      <Router>
        {isAuthenticated &&
          (currentLocation === '/inicio' ||
            currentLocation.indexOf('/serie/') >= 0 ||
            currentLocation.indexOf('/evento/') >= 0 ||
            currentLocation === '/categorias' ||
            currentLocation === '/favoritos' ||
            currentLocation === '/cuenta') ? (
          <>
            {currentLocation !== '/cuenta' && !isMobile && <NavMenu currentProfile={currentProfile} />}
            <CompensationModalComponent openModal={openModal} setOpenModal={setOpenModal} />
          </>
        ) : null}
        <ScrollToTop
          setCurrentLocation={setCurrentLocation}
        />
        {showError429 && <React.Fragment><Error429 /></React.Fragment>}
        <Routes>
          {!isMobile ? (
            <>
              <Route path='/' exact key='/onboardingDesktop' element={<OnboardingComponent />} />,
              <Route path='/directing' exact key='/directing' element={<DirectingComponent isActivePrehome={preHomeData?.active || false} />} />,
              <Route path='/prehome' exact key='/prehome' element={<PreHomeComponent preHomeTitle={preHomeData?.title || ''} preHomeUrl={preHomeData?.url || ''} preHomeTextButton={preHomeData?.textButton || ''} preHomeTimeOut={preHomeData?.timeout || 0} />} />,
              <Route path='/inicio' exact key='/inicio' element={<HomeComponent />} />,
              <Route path='/serie/:_id' exact key='/serie' element={<SerieComponent />} />,
              <Route path='/evento/:_id' exact key='/evento' element={<EventComponent />} />,
              <Route path='/player/:type/:_id' key='/player' element={<PlayerComponent />} />,
              <Route path='/categorias' exact key='/categorias' element={<CategoriesComponent />} />,
              <Route path='/favoritos' exact key='/favoritos' element={<FavoritesComponent />} />,
              <Route path='/cuenta/editar/:id' key='/editar' element={<ProfileEditComponent />} />,
              <Route path='/cuenta/seleccionar' key='/seleccionar' element={<ProfileSelectionComponent />} />
            </>
          ) : (
            <>
              <Route path='/' exact key='/onboardingMobile' element={<OnboardingComponent />} />,
              <Route path='/descargar-app' exact key='/descargar-app' element={<DownloadAppContainer isIOS={isIOS} isFreePlan={isFreePlan} />} />
            </>
          )}
          <Route path='/cuenta/plan' element={<ProfilePlanComponent />} />
          <Route path='/cuenta' exact element={<ProfileComponent setOpenModal={setOpenModal} />} />
          <Route path='/registro' element={<RegisterComponent />} />
          <Route path='/planes/:id' element={<PlansComponent />} />
          <Route path='/planes' element={<PlansComponent />} />
          <Route path='/payment-validation' element={<PaymentValidationComponent />} />
          <Route path='/contactanos' element={<ContactComponent />} />
          <Route path='/iniciar' Component={() => {
            window.location.href = keycloakConfig.url + 'realms/megamedia/device'
            return null
          }}
          />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </Router>
    </div>
  )
})
